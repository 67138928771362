.vertragspartner_anrede{ grid-area: anrede }
.vertragspartner_vorname{ grid-area: vorname }
.vertragspartner_name{ grid-area: name }
.vertragspartner_strasse{ grid-area: strasse }
.vertragspartner_ortsteil{ grid-area: ortsteil }
.vertragspartner_plz{ grid-area: plz }
.vertragspartner_ort{ grid-area: ort }
.vertragspartner_telefon{ grid-area: telefon }
.vertragspartner_eMail{ grid-area: email }
.vertragspartner_gebTag{ grid-area: gebTag }

.personenAngabenApp{
  display: grid;
  grid-template-areas:
    'anrede'
    'vorname'
    'name'
    'strasse'
    'plz'
    'ort'
    'ortsteil'
    'gebTag'
    'telefon'
    'email';
  grid-gap: 10px;
}

.personenAngabenWeb{
  display: grid;
  grid-template-areas:
    'anrede strasse strasse telefon'
    'vorname plz ort email'
    'name ortsteil ortsteil gebTag';
  grid-template-columns: 33% 10% 22% 33%;
  grid-gap: 10px;
}

.personenAngabenAbschluss{
  display: grid;
  grid-template-areas:
    'anrede strasse gebTag'
    'vorname plz email'
    'name ort telefon'
    '. ortsteil .';
  grid-template-columns: 33% 33% 33%;
  /*grid-gap: 10px;*/
}

.vertragspartner_anrede{ grid-area: anrede }
.vertragspartner_vorname{ grid-area: vorname }
.vertragspartner_name{ grid-area: name }
.vertragspartner_strasse{ grid-area: strasse }
.vertragspartner_ortsteil{ grid-area: ortsteil }
.vertragspartner_plz{ grid-area: plz }
.vertragspartner_ort{ grid-area: ort }
.vertragspartner_telefon{ grid-area: telefon }
.vertragspartner_eMail{ grid-area: email }
.vertragspartner_gebTag{ grid-area: gebTag }

.essensteilnehmer_anrede{ grid-area: anrede }
.essensteilnehmer_vorname{ grid-area: vorname }
.essensteilnehmer_name{ grid-area: name }
.essensteilnehmer_strasse{ grid-area: strasse }
.essensteilnehmer_ortsteil{ grid-area: ortsteil }
.essensteilnehmer_plz{ grid-area: plz }
.essensteilnehmer_ort{ grid-area: ort }
.essensteilnehmer_telefon{ grid-area: telefon }
.essensteilnehmer_eMail{ grid-area: email }
.essensteilnehmer_gebTag{ grid-area: gebTag }

.rechnungskunde_anrede{ grid-area: anrede }
.rechnungskunde_vorname{ grid-area: vorname }
.rechnungskunde_name{ grid-area: name }
.rechnungskunde_strasse{ grid-area: strasse }
.rechnungskunde_ortsteil{ grid-area: ortsteil }
.rechnungskunde_plz{ grid-area: plz }
.rechnungskunde_ort{ grid-area: ort }
.rechnungskunde_telefon{ grid-area: telefon }
.rechnungskunde_eMail{ grid-area: email }
.rechnungskunde_gebTag{ grid-area: gebTag }

#einrichtungMobil{
  display: grid;
  grid-template-areas:
    'e_name e_name'
    'e_gruppe e_gruppe';
  grid-gap: 10px;
}

#einrichtungMobilFreitext{
  display: grid;
  grid-template-areas:
    'e_freitext';
  grid-gap: 10px;
}

.einrichtungWeb{
  grid-template-areas:
    'e_name e_gruppe' !important;
  grid-template-columns: 33% 33% 33%;
}

.einrichtungWebFreitext{
  grid-template-areas:
    'e_freitext' !important;
  grid-template-columns: 33% 33% 33%;
}

.einrichtung_nameEinrichtung{ grid-area: e_name }
.einrichtung_nameGruppe{ grid-area: e_gruppe }
.einrichtung_FreitextInput{ grid-area: e_freitext }

#bankverbindungMobil{
  display: grid;
  grid-template-areas:
    'b_kontoinhaber b_kontoinhaber'
    'b_iban b_iban'
    'b_bic b_bic';
  grid-gap: 10px;
}

.bankverbindungWeb{
  grid-template-areas:
    'b_kontoinhaber b_iban b_bic' !important;
  grid-template-columns: 33% 33% 33%;
}

.bankverbindung_kontoinhaber{ grid-area: b_kontoinhaber }
.bankverbindung_iban{ grid-area: b_iban }
.bankverbindung_bic{ grid-area: b_bic }

#zahlungMobil{
  display: grid;
  grid-template-areas:
    'zahlungsart zahlungsart'
    'zahlintervall zahlintervall';
  grid-gap: 10px;
}

.zahlungWeb{
  grid-template-areas:
    'zahlungsart zahlintervall' !important;
  grid-template-columns: 33% 33% 33%;
}

.zahlungsart{ grid-area: zahlungsart }
.zahlintervall{ grid-area: zahlintervall }

#anmeldungMobil{
  display: grid;
  grid-template-areas:
    'passwort passwort'
    'passwort2 passwort2';
  grid-gap: 10px;
}

.anmeldungWeb{
  grid-template-areas:
    'passwort passwort2' !important;
  grid-template-columns: 33% 33% 33%;
}

.passwort{ grid-area: passwort }
.passwort2{ grid-area: passwort2 }

#zusatzMobil{
  display: grid;
  grid-template-areas:
    's_leistungsBeginn'
    's_karte'
    's_freitext'
    's_kostenzusage'
    's_but'
    's_zahlungseinwilligung'
    's_rechPerMail'
    's_rechtsZustimmung';
}

.zusatzWeb{
  grid-template-areas:
    's_freitext s_freitext s_freitext'
    's_leistungsBeginn s_karte .'
    's_kostenzusage s_kostenzusage s_kostenzusage'
    's_but s_but s_but'
    's_zahlungseinwilligung s_zahlungseinwilligung .'
    's_rechPerMail s_rechPerMail .'
    's_rechtsZustimmung s_rechtsZustimmung .'
  !important;
  grid-template-columns: 33% 33% 33%;
}

.zusatzAbschluss{
  display: grid;
  grid-template-areas:
    's_karte'
    's_leistungsBeginn'
    's_kostenzusage'
    's_but'
    's_zahlungseinwilligung'
    's_rechPerMail'
    's_rechtsZustimmung'
    's_freitext';
}

.zahlungseinwilligung{ grid-area: s_zahlungseinwilligung }
.but{ grid-area: s_but }
.leistungsBeginn{ grid-area: s_leistungsBeginn }
.kostenzusage{ grid-area: s_kostenzusage }
.karte{ grid-area: s_karte }
.freiText{ grid-area: s_freitext }
.rechPerMail{ grid-area: s_rechPerMail }
.rechtsZustimmung{ grid-area: s_rechtsZustimmung }

.registrationFeldHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registrationFeldHeader mat-icon{
  color: var(--primary);
  overflow: visible;
}

select.registrationFormFehler{
  border: 1px var(--bad) solid !important;
}

input.registrationFormFehler{
  border: 1px var(--bad) solid;
}

textarea.registrationFormFehler{
  border: 1px var(--bad) solid !important;
}

mat-checkbox.registrationFormFehler label{
  color: var(--bad);
}

.registrationBeschreibungFehler{
  color: var(--bad);
  font-size: 13px;
}

#zusatzMobil .leistungsBeginn, .karte{
  margin: 0 0 5px 0;
}

.zusatzWeb .leistungsBeginn{
  margin: 0 5px 5px 0 !important;
}

.zusatzWeb .registrationFeldHeader{
  height: 24px !important;
}

#zusatzMobil .registrationFeldHeader{
  height: fit-content;
}
